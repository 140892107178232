import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import dropdownReducer from './dropdowns/dropdownSlice';
import employeeSlice from './employee/employeeSlice';
import expenseSlice from './expense/expenseSlice';
import payrollSlice from './payroll/payrollSlice';
export default combineReducers({
    auth: authReducer,
    user: userReducer,
    dropdown: dropdownReducer,
    employee: employeeSlice,
    expense: expenseSlice,
    payroll: payrollSlice
});
