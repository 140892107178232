var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
var onPaste = function (e) {
    var ranges = [
        '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])', // U+1F680 to U+1F6FF
    ];
    if (e.clipboardData.getData('text/plain').match(ranges.join('|'))) {
        e.preventDefault();
        return false;
    }
};
var onkeydownEvent = function (e, handleChange) {
    var ranges = [
        '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])', // U+1F680 to U+1F6FF
    ];
    if (e.target.value && e.target.value.match(ranges.join('|'))) {
        e.preventDefault();
        return false;
    }
    handleChange(e);
};
export var Input = function (_a) {
    var success = _a.success, rows = _a.rows, height = _a.height, width = _a.width, type = _a.type, value = _a.value, label = _a.label, error = _a.error, helperText = _a.helperText, readOnly = _a.readOnly, disabled = _a.disabled, handleChange = _a.handleChange, name = _a.name, bgcolor = _a.bgcolor;
    return (_jsx("div", __assign({ className: "".concat(width) }, { children: _jsx(TextField, { autoComplete: 'off', type: type, rows: rows, name: name, 
            // multiline
            fullWidth: true, onPaste: onPaste, onChange: function (e) { return onkeydownEvent(e, handleChange); }, disabled: disabled, inputProps: { readOnly: readOnly, autoComplete: 'off' }, error: error, helperText: helperText, sx: {
                '& .MuiInputBase-input': {
                    color: '#141C4C',
                    borderRadius: '8px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    // borderColor: '#E7E8ED',
                    borderRadius: '8px',
                },
                backgroundColor: bgcolor || 'transparent',
                borderRadius: '8px',
                textarea: { color: 'black', height: height },
                // label: { color: success ? '#3AC730' : '#141C4C' },
                '& .MuiFormLabel-root.Mui-hovered': {
                    color: 'red',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                    color: '#0C8EC7',
                },
                '& .MuiFormLabel-root.Mui-hover': {
                    color: 'red',
                },
                '& .MuiOutlinedInput-root:hover': {
                    '& > fieldset': {
                        color: 'red',
                        borderColor: '#141C4C',
                    },
                },
                '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    '& > fieldset': {
                        borderColor: success ? '#3AC430' : '#E7E8ED',
                    },
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                    '& > fieldset': {
                        borderColor: '#0C8EC7',
                    },
                },
            }, variant: 'outlined', value: value, label: label }) })));
};
