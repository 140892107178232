var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    userStatusDropdown: [],
    userRolesDropdown: [],
    status: true,
    rolesData: [],
    samplesDropdown: [],
    hubs: [],
    testsDropdown: [],
    testAndGroupsDropdown: [],
    leadStatusDropdown: [],
    executiveDropdown: [],
    leadSourcesDropdown: [],
};
export var dropdownSlice = createSlice({
    name: 'dropdown',
    initialState: initialState,
    reducers: {
        StatusDropdownSuccess: function (state, action) { },
        StatusDropdownPending: function (state, action) { },
        StatusDropdownFailed: function (state, action) { },
        RoleDropdownSuccess: function (state, action) {
            state.userRolesDropdown = action.payload.data;
        },
        RoleDropdownPending: function (state, action) { },
        RoleDropdownFailed: function (state, action) { },
        DropDownPending: function (state, action) { },
        DropDownSuccess: function (state, action) {
            console.log('status & sources:', action === null || action === void 0 ? void 0 : action.payload.data);
            state.leadStatusDropdown = action.payload.data.status;
            state.leadSourcesDropdown = action.payload.data.source;
        },
        DropDownFailure: function (state, action) { },
        RolesPending: function (state, action) { },
        RolesSuccess: function (state, action) {
            console.log('roles dropdown:', action === null || action === void 0 ? void 0 : action.payload.data);
            state.rolesData = action.payload.data;
        },
        RolesFailure: function (state, action) { },
        SampleDropdownPending: function (state, action) { },
        SampleDropdownSuccess: function (state, action) {
            var _a, _b;
            console.log('samplesDropdown dropdown success', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.samplesDropdown = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data;
        },
        SampleDropdownFailure: function (state, action) { },
        TestsDropdownPending: function (state, action) { },
        TestsDropdownSuccess: function (state, action) {
            var _a, _b;
            console.log('tests dropdown success', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.testsDropdown = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data;
        },
        TestsDropdownFailure: function (state, action) { },
        testAndGroupsDropdownSuccess: function (state, action) {
            console.log('roles dropdown:', action === null || action === void 0 ? void 0 : action.payload.data);
            state.testAndGroupsDropdown = action.payload.data;
        },
        testAndGroupsDropdownFailure: function (state, action) { },
        hubsDropdownSuccess: function (state, action) {
            state.hubs = action.payload.data;
        },
        ExecutiveSuccess: function (state, action) {
            console.log('action:', action.payload);
            state.executiveDropdown = action.payload.data;
        },
        ExecutiveFailure: function (state, action) { },
        LeadSourcesSuccess: function (state, action) {
            state.leadSourcesDropdown = action.payload.data.source;
        },
        LeadSourceFailure: function (state, action) { },
    },
});
export var LeadSourcesSuccess = (_a = dropdownSlice.actions, _a.LeadSourcesSuccess), LeadSourceFailure = _a.LeadSourceFailure, ExecutiveSuccess = _a.ExecutiveSuccess, ExecutiveFailure = _a.ExecutiveFailure, StatusDropdownSuccess = _a.StatusDropdownSuccess, StatusDropdownPending = _a.StatusDropdownPending, RoleDropdownSuccess = _a.RoleDropdownSuccess, RoleDropdownPending = _a.RoleDropdownPending, RoleDropdownFailed = _a.RoleDropdownFailed, StatusDropdownFailed = _a.StatusDropdownFailed, DropDownSuccess = _a.DropDownSuccess, DropDownPending = _a.DropDownPending, DropDownFailure = _a.DropDownFailure, RolesPending = _a.RolesPending, RolesSuccess = _a.RolesSuccess, RolesFailure = _a.RolesFailure, SampleDropdownPending = _a.SampleDropdownPending, SampleDropdownSuccess = _a.SampleDropdownSuccess, SampleDropdownFailure = _a.SampleDropdownFailure, TestsDropdownPending = _a.TestsDropdownPending, TestsDropdownSuccess = _a.TestsDropdownSuccess, TestsDropdownFailure = _a.TestsDropdownFailure, testAndGroupsDropdownSuccess = _a.testAndGroupsDropdownSuccess, testAndGroupsDropdownFailure = _a.testAndGroupsDropdownFailure, hubsDropdownSuccess = _a.hubsDropdownSuccess;
export default dropdownSlice.reducer;
export var fetchUserRoleDropdown = function () {
    return apiCallBegan({
        url: '/admin/roles/dropdown',
        method: 'GET',
        onStart: RoleDropdownPending.type,
        onSuccess: RoleDropdownSuccess.type,
        onError: RoleDropdownFailed.type,
    });
};
