var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from '../../utils/helpers';
import moment from 'moment';
var initialState = {
    expenseList: [],
    isloading: false,
    metadata: {
        totalUsers: 0,
        totalPages: 0,
        total: 0,
    }
};
export var expenseSlice = createSlice({
    name: 'expense',
    initialState: initialState,
    reducers: {
        ExpenseListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            state.isloading = false;
            state.expenseList = action.payload.data.data || [];
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalUsers = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_users;
        },
        ExpenseListPending: function (state, action) {
            state.isloading = true;
        },
        ExpenseListFailed: function (state, action) {
            state.isloading = false;
        },
        ExpenseStatusSuccess: function (state, action) {
            console.log('action:', action.payload);
            showToastMessage(action.payload.data.message, 'success');
        },
        ExpenseStatusFailed: function (state, action) {
            showToastMessage(action.payload.message, 'error');
        }
    },
});
export var ExpenseListSuccess = (_a = expenseSlice.actions, _a.ExpenseListSuccess), ExpenseListPending = _a.ExpenseListPending, ExpenseListFailed = _a.ExpenseListFailed, ExpenseStatusSuccess = _a.ExpenseStatusSuccess, ExpenseStatusFailed = _a.ExpenseStatusFailed;
export default expenseSlice.reducer;
export var fetchExpense = function (data) {
    return apiCallBegan({
        url: "/admin/expenses?search_key=".concat(data.search_key, "&page=").concat(data.page, "&date=").concat(data.date ? moment(data.date).format('YYYY-MM-DD') : '', "&type=").concat(data.type || ''),
        method: 'GET',
        data: data,
        onStart: ExpenseListPending.type,
        onSuccess: ExpenseListSuccess.type,
        onError: ExpenseListFailed.type,
    });
};
