var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CustomButton from './Common/Button';
import { Link } from 'react-router-dom';
var PasswordSuccess = function () { return (_jsx("div", { children: _jsxs("div", __assign({ className: " flex flex-col justify-center items-center p-6 sm:p-12 rounded-lg  " }, { children: [_jsx("p", __assign({ className: "text-3xl font-black text-yellow" }, { children: "Done !" })), _jsx("p", __assign({ className: "text-center sm:text-left text-xs text-white mb-8" }, { children: "Your New Password has been Saved Successfully!" })), _jsx(CustomButton, __assign({ variant: "contained" }, { children: _jsx(Link, __assign({ to: "/login" }, { children: "Login with New Password" })) }))] })) })); };
export default PasswordSuccess;
