var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from '../../utils/helpers';
var initialState = {
    employeeList: [],
    isloading: false,
    metadata: {
        totalUsers: 0,
        totalPages: 0,
        total: 0,
    }
};
export var employeeSlice = createSlice({
    name: 'employee',
    initialState: initialState,
    reducers: {
        EmployeeListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            state.isloading = false;
            state.employeeList = action.payload.data.data || [];
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalUsers = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_users;
        },
        EmployeeListPending: function (state, action) {
            state.isloading = true;
        },
        EmployeeListFailed: function (state, action) {
            state.isloading = false;
        },
        EmployeeStatusSuccess: function (state, action) {
            console.log('action:', action.payload);
            showToastMessage(action.payload.data.message, 'success');
        },
        EmployeeStatusFailed: function (state, action) {
            showToastMessage(action.payload.message, 'error');
        }
    },
});
export var EmployeeListSuccess = (_a = employeeSlice.actions, _a.EmployeeListSuccess), EmployeeListPending = _a.EmployeeListPending, EmployeeListFailed = _a.EmployeeListFailed, EmployeeStatusSuccess = _a.EmployeeStatusSuccess, EmployeeStatusFailed = _a.EmployeeStatusFailed;
export default employeeSlice.reducer;
export var fetchEmployees = function (data) {
    return apiCallBegan({
        url: "/admin/employees?status=".concat(data.status, "&search_key=").concat(data.search_key, "&&page=").concat(data.page),
        method: 'GET',
        data: data,
        onStart: EmployeeListPending.type,
        onSuccess: EmployeeListSuccess.type,
        onError: EmployeeListFailed.type,
    });
};
export var UpdateEmployeeStatus = function (id, data) {
    return apiCallBegan({
        url: "/admin/employees/update-status/".concat(id),
        method: 'PATCH',
        data: data,
        onSuccess: EmployeeStatusSuccess.type,
        onError: EmployeeStatusFailed.type,
    });
};
