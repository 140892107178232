var _a;
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    payrollList: [],
    isloading: false,
    metadata: {
        totalUsers: 0,
        totalPages: 0,
        total: 0,
    },
    totalStats: {
        total_net_pay: 0,
        paid: 0,
        pending: 0,
    }
};
export var payrollSlice = createSlice({
    name: 'payroll',
    initialState: initialState,
    reducers: {
        PayRollListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f, _g;
            state.isloading = false;
            state.payrollList = action.payload.data.data || [];
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalUsers = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_users;
            state.totalStats = (_g = action === null || action === void 0 ? void 0 : action.payload) === null || _g === void 0 ? void 0 : _g.total_payrolls;
        },
        PayRollListPending: function (state, action) {
            state.isloading = true;
        },
        PayRollListFailed: function (state, action) {
            state.isloading = false;
        }
    },
});
export var PayRollListSuccess = (_a = payrollSlice.actions, _a.PayRollListSuccess), PayRollListPending = _a.PayRollListPending, PayRollListFailed = _a.PayRollListFailed;
export default payrollSlice.reducer;
export var fetchPayRolls = function (data) {
    return apiCallBegan({
        url: "/admin/payrolls?search_key=".concat(data.search_key, "&page=").concat(data.page, "&payment_status=").concat(data.payment_status, "&date=").concat(data.date ? moment(data.date).format('YYYY-MM-DD') : '', "&department=").concat(data.department),
        method: 'GET',
        data: data,
        onStart: PayRollListPending.type,
        onSuccess: PayRollListSuccess.type,
        onError: PayRollListFailed.type,
    });
};
